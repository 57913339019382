<template>
<LayoutPage type="app-tabs">
    <a-tabs type="card" @change="callback">
      <a-tab-pane key="1" tab="修改密码">
        <div class="page">
          <div class="content">
              <div class="search">
                  <a-form-model
                    ref="form"
                    :model="form"
                    :rules="rules"
                  >
                  <div style="margin-bottom: 32px;">
                      <div style="color: #2A3A59;font-size:20px;font-weight: 500;margin-bottom: 30px;">修改密码</div>
                      <div style="color: #2A3A59;font-size:16px;margin-bottom: 20px;">请输入原密码</div>
                      <a-form-model-item prop="oldPassword ">
                        <a-input style="width:340px;" v-model="form.oldPassword " placeholder="请输入" />
                      </a-form-model-item>
                  </div>
                  <div style="margin-bottom: 32px;">
                      <div style="color: #2A3A59;font-size:16px;margin-bottom: 23px;">请输入新密码</div>
                      <div style="margin-bottom: 11px;">新密码为<span style="color:#ef4b5b">6-18</span>位，只能由<span style="color:#ef4b5b">数字+大小写字母</span>组成</div>
                      <a-form-model-item prop="newPassword">
                        <a-input-password style="width:340px;" type="pass" @change="passWord" v-model="form.newPassword" placeholder="请输入" />
                      </a-form-model-item>
                  </div>
                </a-form-model>
                <a-button :loading="loading" @click="handleOk" type="primary">提交</a-button>
              </div>

          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </LayoutPage>
</template>

<script>
import { appModifyPass } from '@/api/app'

export default {

  data () {
    return {
      form: {
        oldPassword: '',
        newPassword: ''
      },
      disabled: true,
      licencePlate: '',
      loading: false,
      rules: {
        oldPassword: [{ required: true, message: '必填', trigger: 'blur' }
        // ,
        //   {
        //     validator: (rules, value, callback) => {
        //       const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/
        //       if (!reg.test(value)) {
        //         return callback(new Error('密码至少包含大写字母，小写字母，数字，且不少于8位'))
        //       } else {
        //         return callback()
        //       }
        //     }
        //   }
        ],
        newPassword: [{ required: true, message: '必填', trigger: 'blur' },
          {
            validator: (rules, value, callback) => {
              const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{6,18}$/
              if (!reg.test(value)) {
                return callback(new Error('密码至少包含大写字母，小写字母，数字，且不少于6位'))
              } else {
                return callback()
              }
            }
          }
        ]
      }
    }
  },
  created () {
  },
  methods: {
    passWord () {

    },
    handleOk () {
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          setTimeout(() => {
            this.loading = false
          }, 2000)
          appModifyPass(this.form).then((res) => {
            this.loading = false
            this.$message.success('操作成功')
          })
        } else {
          return false
        }
      })
    },
    callback () {

    }
  }
}
</script>

<style scoped lang="less">
/deep/ .page{
  width: 100%;

  .content{
    height: 100%;
    position: relative;
    background: #ffffff;
    border: 1px solid #DDDDDD;
    border-radius: 10px;
    padding: 24px 0 0 28px;
    .search{
      height: 100%;
      margin-bottom: 20px;
    }
  }
}

</style>>
